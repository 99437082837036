// ----------------------------------------------------------------------

function path(root, sublink) {
    return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_ADMIN = '/admin';
const ROOTS_DASHBOARD = '';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
    root: ROOTS_AUTH,
    login: path(ROOTS_AUTH, '/login'),
};

export const PATH_DASHBOARD = {
    root: ROOTS_DASHBOARD,
    general: {
        app: path(ROOTS_DASHBOARD, '/home'),
        createOrder: path(ROOTS_DASHBOARD, '/create-order'),
        settings: path(ROOTS_DASHBOARD, '/settings'),
    },
    shop:{
        root: path(ROOTS_DASHBOARD, "/shop"),
        checkout: path(ROOTS_DASHBOARD, "/shop/checkout"),
        details: (name) => path(ROOTS_DASHBOARD, `/shop/${name}`),
    },
    clients: {
        root: path(ROOTS_DASHBOARD, "/clients"),
        preview: path(ROOTS_DASHBOARD, "/clients/preview"),
        newEsir: path(ROOTS_DASHBOARD, "/clients/new-esir"),
        newEfacture: path(ROOTS_DASHBOARD, "/clients/new-eFacture"),
        newOperator: path(ROOTS_DASHBOARD, "/clients/new-operator"),
        editOperator: path(ROOTS_DASHBOARD, "/clients/edit-operator"),
        newLocation: path(ROOTS_DASHBOARD, "/clients/new-location"),
        editLocation: path(ROOTS_DASHBOARD, "/clients/edit-location"),
        newLicense: path(ROOTS_DASHBOARD, "/clients/new-license"),
        editLicense: path(ROOTS_DASHBOARD, "/clients/edit-license"),
    },
    chat: {
        root: path(ROOTS_DASHBOARD, "/chat"),
        new: path(ROOTS_DASHBOARD, "/chat/new"),
        view: (name) => path(ROOTS_DASHBOARD, `/chat/${name}`),
    },
    specification: {
        root: path(ROOTS_DASHBOARD, "/specification"),
        preview: path(ROOTS_DASHBOARD, "/specification/preview")
    },
    help: {
        root: path(ROOTS_DASHBOARD, "/help")
    },
    settings:{
        root: path(ROOTS_DASHBOARD, "/settings")
    },
    userOrders:{
        root: path(ROOTS_DASHBOARD, "/my/orders")
    },
    admin: {
        root: ROOTS_ADMIN,
        tools: path(ROOTS_ADMIN, "/tools"),
        payments: path(ROOTS_ADMIN, "/payments"),
        debtors: {
            root: path(ROOTS_ADMIN, "/debtors"),
            all: path(ROOTS_ADMIN, "/debtors/all"),
            active: path(ROOTS_ADMIN, "/debtors/active"),
            inactive: path(ROOTS_ADMIN, "/debtors/inactive"),
        },
        allUsers: {
            root: path(ROOTS_ADMIN, "/all-users"),
            standard: path(ROOTS_ADMIN, "/all-users/standard"),
            advanced: path(ROOTS_ADMIN, "/all-users/advanced"),
        },
        orders: path(ROOTS_ADMIN, "/orders"),
        products: {
            root: path(ROOTS_ADMIN, "/products"),
            new: path(ROOTS_ADMIN, "/products/new"),
            edit: path(ROOTS_ADMIN, "/products/edit"),
        },
        tenants: {
            root: path(ROOTS_ADMIN, "/tenants"),
            preview: path(ROOTS_ADMIN, "/tenants/preview"),
            new: path(ROOTS_ADMIN, "/tenants/new"),
            report: path(ROOTS_ADMIN, "/tenants/report"),
        }
    },
};

