import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {getAllClientsOffline} from "../../store/offlineDb";
import {ALL_LICENSE} from "../../constants";
import moment from "moment";

const getYearlyState = (data) => {
    let years = {};
    let total = {};
    for (const el of data) {
        let date = moment(el.contractStartDate);
        const year = date.get("year");
        const month = (date.get("month") + 1);
        if (!years[year]) {
            total[year] = 1
            years[year] = {
                [month]: 1
            };
        } else {
            total[year] = total[year] + 1
            if (!years[year][month]) {
                years[year] = {
                    ...years[year],
                    [month]: 1
                };
            } else {
                years[year] = {
                    ...years[year],
                    [month]: years[year][month] + 1
                };
            }
        }
    }
    return {
        years,
        total
    };
};

const countLicense = (users, allLicense) => {
    const getLicenseName = (productID) => {
        if (productID === undefined) {
            return "Ostalo";
        }
        return allLicense.find(item => parseInt(item.id) === parseInt(productID))?.name || "Ostalo";
    };

    let licenseCount = {}
    for (const user of users) {
        for (const l of user?.license) {
            if(!l){
                console.debug("INVALID: ",user)
            }
            const name = getLicenseName(l?.productID);
            if (name) {
                if (licenseCount[name]) {
                    licenseCount = {
                        ...licenseCount,
                        [name]: licenseCount[name] + parseInt(l?.count)
                    }
                } else {
                    licenseCount = {
                        ...licenseCount,
                        [name]: parseInt(l?.count)
                    }
                }
            }
        }
    }
    return licenseCount;
}

export const fetchAnalyticsData = createAsyncThunk("fetchAnalyticsData", async (data) => {
    const {publicLicense} = data;
    let clients = await getAllClientsOffline();
    const active = clients.filter(client => !client.disabled);
    const inactive = clients.filter(client => client.disabled);
    const esir = active.filter(client => client.license.some(license => license?.productID === ALL_LICENSE.Esir && license?.count > 0));
    const lpfr = active.filter(client => client.license.some(license => license?.productID === ALL_LICENSE.Lpfr && license?.count > 0));
    const eFacture = active.filter(client => client.license.some(license => license?.productID === ALL_LICENSE.eFacture && license?.count > 0));
    const byYears = getYearlyState(clients);
    return {
        licenseCount: countLicense(active, publicLicense),
        esirUsers: esir,
        lpfrUsers: lpfr,
        activeUsers: active,
        eFactureUsers: eFacture,
        totalUsers: clients.length,
        totalActiveUsers: active.length,
        totalInactiveUsers: inactive.length,
        years: byYears.years,
        totalByYears: byYears.total,
    };
});

const initialState = {
    data: {
        licenseCount: {},
        activeUsers: [],
        esirUsers: [],
        lpfrUsers: [],
        eFactureUsers: [],
        totalUsers: 0,
        totalActiveUsers: 0,
        totalInactiveUsers: 0,
        years: {},
        totalByYears: {}
    },
    loading: false
};

export const slice = createSlice({
    name: "analytics",
    initialState,
    reducers: {},
    extraReducers: {
        [fetchAnalyticsData.pending]: (state) => {
            state.loading = true;
        },
        [fetchAnalyticsData.fulfilled]: (state, {payload}) => {
            state.data = payload;
            state.loading = false;
        },
        [fetchAnalyticsData.rejected]: (state) => {
            state.loading = false;
        }
    }
});
export default slice.reducer;


